
.hero {
    background:linear-gradient(rgba(3, 3, 3, 0.9),rgba(6, 99, 99, 0.9));
    height: 67vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    color:white;
}

.main {
    height:67vh;
}

.greeting {
    /* Styles for the greeting section */
    font-family: "Red Hat Display";
    color: #FF0000;
    font-weight: 800;
    font-size: 1.9em;
  }



/* techstack.module.css */

@keyframes upDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animated-icon {
    animation: upDown 2s infinite ease-in-out;
  }
  